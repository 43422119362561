<template>
  <div>
    <div style="width: 92%;margin: auto">
      <h2 style="text-align: center">公司简介</h2>
      <div>深圳市维拍物联智能技术有限公司是一个全球化的智能
        物联网云平台。</div>
      <div style="font-size: 13px;margin-top: 3vh;color: #858585">
        始创于2015年，隶属深圳市威视达康科技有限公司。成立之初便致力于通过“loT+云平台”，一站式方案解决企业视频产品的智能化升级。
        维拍公司专注于以视频技术为核心，涉足AI、物联网等方向，其中包含人脸识别、人脸追踪、人形识别、图像识别及智能模块互联等技术
        ，拥有数十项国家高新专利，是国内领先的智能视频产品方案提供商。公司研发团队高达百余人，资深、高效、专业。
      </div>
      <img style="margin-top: 5vh" src="../../../public/images/qt/jianjie/jianjie1.jpg" width="100%" height="100%">

      <div style="font-size: 13px;margin-top: 3vh;color: #858585">
        VeePai云平台是维拍公司旗下集云存储、云控制等多功能智慧平台。平台覆盖全球190+国家地区，180+云产品服务，超200w+开发者，服务3000+企业。
        为企业提供安全稳定的云平台服务，支持多语种模式，加快企业全球化进程。
      </div>

      <div style="font-size: 13px;margin-top: 3vh;color: #858585">
        VeePai云将发挥软硬件协同优势，全面提升用户体验、云端服务和安全能力，持续为企业客户打造全球最佳体验的云服务，同时不断更新智能视频产品终端，
        为企业提供智能视频产品升级方案闭环。
      </div>

      <img style="margin-top: 5vh" src="../../../public/images/qt/jianjie/jianjie2.jpg" width="100%" height="100%">

      <div style="font-size: 13px;margin-top: 3vh;color: #858585">
        为客户创造价值是我们始终如一的坚持，在这个坚持的过程中，维拍在全球进行云覆盖布局，实现全球亿万人互联。维拍为企业提供开放、灵活、安全的云平台基础设施
        ，推动企业数字化、智能化转型；为云服务客户提供稳定可靠、安全可信和持续创新的云服务。维拍的VeePai云平台和智能视频产品，正在帮助企业高效的完成智能化、数字化转型工作
      </div>

      <div style="font-size: 13px;margin-top: 3vh;color: #858585;height: 20vh">
        维拍一直秉承着“客户至上、使命必达”的核心理念。产品不断创新，服务不断优化，技术不断升级，多维度上满足企业数字化、智能化升级所需。竭力为合作伙伴深耕市场，进行全球化布局。
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "公司简介"
}
</script>

<style scoped>

</style>